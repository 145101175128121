// pro install onTech types
export type BrazeChildComponentWithSku = {
  readonly name: string
  readonly quantity: string
  readonly sku: string
}

export type BrazeChildComponents = readonly BrazeChildComponentWithSku[]

export type BrazeLineItem = {
  readonly name: string
  readonly sku: string
  readonly quantity: number
  readonly packageComponents?: BrazeChildComponents
}

export type FlattenedComponents = {
  readonly name: string
  readonly quantity: number
  readonly sku: string
}

export const systemPieceSkus: readonly string[] = [
  'SSBS3W', //Base station,
  'SSKP3W', //Keypad
  'SSWS3', //Siren
  'SSKF3B', //FOB
  'SSES3', //Entry sensor
  'CA001-01DWW', //Motion sensor
  'SSGB3', //Glassbreak sensor
  'SSPB3', //Panic button
  'SSWT3', //Water sensor
  'SSFS3', //Temperature sensor
  'SSCM2', //Indoor Camera (Wired)
  'CM006-01DWW', //Indoor camera (Wireless)
  'CM006-01DWW-INC', //Indoor camera (Wireless Included)
  'SSCO3', //CO Detector
  'SSSD3', //Smoke detector	CO detector
  'CA002-01DWW' //Smoke & CO detector
]

export const excludeFromQuantitySkus: readonly string[] = [
  'SSYS3', // SimpliSafe Yard Sign
  'MI023', // Window Decals
  'CM006-01DWW-INC', //Indoor camera (Wireless Included)
  // These are separate installation components and should not be included in the quantity
  'CA010-01DUS', //smart lock white
  'CA010-02DUS', //smart lock black
  'SSDB3', // video doorbell
  'CMOB1', // outdoor camera
  'SSCATM1-SS3-VZ', // wireless module
  'CM021-01DWW', // Olympus outdoor camera
  'CMCBL1' // Olympus power cable
]

//keys: sku, value: onTech variant ID
export enum AddOnSkuToVariantId {
  'CA010-01DUS' = 39301230035008, // smart lock white
  'CA010-02DUS' = 39301230035008, //smart lock black
  SSDB3 = 39301230002240, // video doorbell
  CMOB1 = 39301229936704, // outdoor camera
  'CM021-01DWW' = 39301229936704, // Olympus outdoor camera
  'CM006-01DWW' = 39301229969472 // Smart Alarm Wireless Indoor Security Camera
}

export enum OntechPackageAddOns {
  smartLock = 39301230035008, // smart lock
  videoDoorbell = 39301230002240, // video doorbell
  outdoorCamera = 39301229936704, // outdoor camera
  indoorCamera = 39301229969472 // Smart Alarm Wireless Indoor Security Camera
}

export enum OntechPackage {
  piece_10 = 39301229838400,
  piece_15 = 39301229871168,
  piece_20 = 39301229903936,
  piece_25 = 39328060047424,
  piece_30 = 39301229805632
}

export type VariantQuantity = {
  readonly variantIdToQuantity: string
}

export type VariantIds = {
  readonly '39301230035008': number //SimpliSafe Smart Lock Installation
  readonly '39301230002240': number //SimpliSafe Video Doorbell Installation
  readonly '39301229936704': number //SimpliSafe Outdoor Cam Installation
  readonly '39301229838400': number //SimpliSafe Up to 10 Pieces Installation
  readonly '39301229871168': number //SimpliSafe Up to 15 Pieces Installation
  readonly '39301229903936': number //SimpliSafe Up to 20 Pieces Installation
  readonly '39328060047424': number //SimpliSafe Up to 25 Pieces Installation
  readonly '39301229805632': number //SimpliSafe Up to 30 Pieces Installation
}

export function isVariantId(
  t: string | keyof VariantIds
): t is keyof VariantIds {
  return (
    t === '39301230035008' ||
    t === '39301230002240' ||
    t === '39301229936704' ||
    t === '39301229838400' ||
    t === '39301229871168' ||
    t === '39301229903936' ||
    t === '39328060047424' ||
    t === '39301229805632'
  )
}

export function isValidKey(
  t: string | keyof typeof AddOnSkuToVariantId
): t is keyof typeof AddOnSkuToVariantId {
  return Object.keys(AddOnSkuToVariantId).includes(t)
}
